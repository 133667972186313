import Vue from 'vue'
import Index from './Index.vue'
import Search from './Search.vue'

import VueTheMask from 'vue-the-mask';
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/app.scss';

import VueRouter from 'vue-router'

Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.use(VueTheMask);
Vue.use(VueSweetalert2);

console.log(window.location.hostname);

const routes = [
  { path: '/', component: window.location.hostname.indexOf('comprovante-busca') >= 0 ? Search : Index },
]


const router = new VueRouter({
   mode: 'history',
  routes
})


new Vue({
  router
}).$mount('#app')
