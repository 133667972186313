<template>
    <div>
        <header>
            <div class="grid-container">
                <div class="layout-group -multiline">
                    <div class="division -12 p-0">
                        <i class="arrow" onclick="window.location.href='https://www.bluefit.com.br/linkdepagamento/cpf'"
                            style="display: none;"></i>
                        <a href="https://www.bluefit.com.br/linkdepagamento/cpf" class="vertical-align">
                            <img src="https://www.bluefit.com.br/themes/linkdepagamento-new/assets/images/logo.png"
                                alt="Logo BlueFit" class="logo">
                        </a>
                    </div>
                </div>
            </div>
        </header>
        <div class="page-index">
            <section class="hero">
                <div class="grid-container h-100">
                    <div class="layout-group -multiline h-100 justify-content-center">
                        <div class="division -12 -text-left-desktop -text-center p-0 box-title">
                            <i class="ico-money"></i>
                            <h3>Verificação<br>Comprovante de Vacinação</h3>

                        </div>
                    </div>
                </div>
            </section>

            <section class="main">
                <div class="grid-container">
                    <div class="layout-group -multiline justify-content-center">
                        <div class="division -text-left-desktop box">
                            <form class="form-control form-style layout-group -multiline" id="payment-form"
                                @submit.prevent="submit" data-request="usuario::onAjax" data-request-flash="">
                                <div class="division -12">
                                    <div class="group-control" id="group-control">
                                        <label for="userCPF">CPF</label>

                                        <input name="cpf" type="text" id="userCPF" class="input-style border"
                                            required="" placeholder="999.999.999-99" v-mask="['###.###.###-##']"
                                            value="" autofocus ref="input" autocomplete="off" maxlength="14"
                                            v-model="cpf" :class="{
                                                'border-red-600': validation_error,
                                                'border-gray-300': !validation_error,
                                                
                                            }">

                                        <div class="mt-4 text-center" v-if="verification !== null">
                                            <p class="text-xl text-green-600 font-bold" v-if="verification">
                                                Possui comprovante!
                                            </p>
                                            <p class="text-xl text-red-700 font-bold"
                                                v-else-if="verification === false">
                                                Não possui comprovante!
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div class="division -12">
                                    <div class="-t-center">

                                        <button class="btn-primary" type="submit"
                                            :disabled="loading">{{ loading ? 'Aguarde...' : 'Verificar' }}</button>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                    <template v-if="url">
                        <img :src="url" class="w-full border-2 border-blue-600 rounded-lg" frameborder=0
                            v-if="url.indexOf('.pdf') == -1" />
                        <embed :src="url" class="w-full h-screen" v-else />
                    </template>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        components: {

        },
        props: {

        },
        data() {
            return {
                url: null,
                validation_error: false,
                cpf: null,
                loading: false,
                verification: null,
            }
        },
        mounted() {

        },
        watch: {
            cpf(value) {

                this.validation_error = value && !this.checkCPF(value) || false;


            }

        },
        methods: {
            submit() {
                if (!this.cpf) {
                    return;
                }
                if (!this.checkCPF(this.cpf)) {
                    return;
                }

                this.loading = true;


                axios.post('/.netlify/functions/search', {
                        cpf: this.cpf
                    })
                    .then(res => res.data)
                    .then(data => {
                        this.verification = data.verification;
                        // setTimeout(() => {
                        //     this.cpf = null;
                        //     this.verification = null;
                        //     this.$refs.input.focus();
                        // }, 5000);
                    })
                    .finally(() => this.loading = false);

                axios.post('/.netlify/functions/get', {
                        cpf: this.cpf
                    })
                    .then(res => res.data)
                    .then(data => {
                        this.url = data.url
                        this.$refs.input.select()
                        // setTimeout(() => {
                        //     this.cpf = null;
                        //     this.verification = null;
                        //     this.$refs.input.focus();
                        // }, 5000);
                    })
                    .catch(e => console.log(e))
                    .finally(() => this.loading = false);

            },

            checkCPF(strCPF) {
                var Soma;
                var Resto;
                Soma = 0;


                strCPF = strCPF.replace(/\D/g, "");
                if (strCPF == "00000000000") return false;

                for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
                Resto = (Soma * 10) % 11;

                if ((Resto == 10) || (Resto == 11)) Resto = 0;
                if (Resto != parseInt(strCPF.substring(9, 10))) return false;

                Soma = 0;
                for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
                Resto = (Soma * 10) % 11;

                if ((Resto == 10) || (Resto == 11)) Resto = 0;
                if (Resto != parseInt(strCPF.substring(10, 11))) return false;
                return true;
            }


        }
    }
</script>