<template>
    <div>
        <header>
            <div class="grid-container">
                <div class="layout-group -multiline">
                    <div class="p-0 division -12">
                        <i class="arrow" onclick="window.location.href='https://www.bluefit.com.br/linkdepagamento/cpf'"
                            style="display: none;"></i>
                        <a href="https://www.bluefit.com.br/linkdepagamento/cpf" class="vertical-align">
                            <img src="https://www.bluefit.com.br/themes/linkdepagamento-new/assets/images/logo.png"
                                alt="Logo BlueFit" class="logo">
                        </a>
                    </div>
                </div>
            </div>
        </header>
        <div class="page-index">
            <section class="hero">
                <div class="grid-container h-100">
                    <div class="layout-group -multiline h-100 justify-content-center">
                        <div class="p-0 division -12 -text-left-desktop -text-center box-title">
                            <i class="ico-money"></i>
                            <h3>Comprovante de Vacinação</h3>
                            <h4 class="mt-6 sm:mx-24">O comprovante é enviado agora na própria unidade.</h4>
                            <!-- <h4 class="mt-6 sm:mx-24">Envie seu comprovante de vacinação completo e mantenha seu
                                treino
                                no
                                blue!
                                💙</h4> -->
                        </div>
                    </div>
                </div>
            </section>

            <!-- <section class="main">
                <div class="grid-container">
                    <div class="layout-group -multiline justify-content-center">
                        <div class="division -text-left-desktop box">
                            <form class="form-control form-style layout-group -multiline" id="payment-form"
                                data-request="usuario::onAjax" data-request-flash="">
                                <div class="division -12">
                                    <div class="group-control" id="group-control">
                                        <label for="userCPF">Seu CPF</label>

                                        <input name="cpf" type="tel" id="userCPF"
                                            class="border border-gray-400 input-style" required=""
                                            placeholder="999.999.999-99" v-mask="['###.###.###-##']" value=""
                                            autocomplete="off" maxlength="14" v-model="cpf">
                                    </div>

                                </div>
                                <div class="division -12">
                                    <div class="-t-center">
                                        <input type="file" class="hidden" ref="file" @change="submit"
                                            accept=".jpg, .jpeg, .png, .pdf" />
                                        <button class="btn-primary" type="button" @click="$refs.file.click()"
                                            :disabled="loading">{{ loading ? 'Enviando...' : 'Selecionar documento' }}</button>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </section> -->
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import axios from 'axios';
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    export default {
        components: {

        },
        props: {

        },
        data() {
            return {
                cpf: null,
                loading: false
            }
        },
        mounted() {

        },
        watch: {

        },
        methods: {
            submit(event) {
                return;
                if (!this.cpf) {
                    return;
                }

                let file = event.target.files[0];

                if (!file) {
                    return;
                }

                if (file.size > 5242880) {
                    this.$swal.fire('Arquivo Grande', 'O arquivo não pode ultrapassar de 5MB', 'error');
                    return;
                }
                if (['image/jpeg', 'application/pdf', 'image/png'].indexOf(file.type) == -1) {
                    this.$swal.fire('Arquivo inválido', 'O arquivo só poderá ser JPG e PDF', 'error');
                    return;
                }


                let formData = new FormData();


                formData.append('file', file);
                formData.append('cpf', this.cpf);


                this.$swal.fire({
                    title: 'Podemos prosseguir?',
                    text: 'Você concorda com o armazenamento e análise dos dados?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sim!',
                    cancelButtonText: 'Não',
                    showLoaderOnConfirm: true,
                    preConfirm: async () => {
                        this.loading = true;

                        // let base64 = await toBase64(file);


                        return await axios.post('/.netlify/functions/upload', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data; charset="utf-8"',
                                }
                            })
                            .catch((e) => this.$swal.showValidationMessage(
                                `Erro: ${e.response.data || e.message}`))
                            .finally(() => this.loading = false);
                    }
                }).then(result => {

                    if (result.isConfirmed) {
                        this.$swal.fire('Pronto!', 'Arquivo recebido com sucesso!', 'success');
                        this.cpf = null;
                    }
                });



            }

        }
    }
</script>